import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Histoire from './pages/Histoire';
import ContactHoraires from './pages/ContactHoraires';
import MentionsLegales from './pages/MentionsLegales';
import ScrollToTop from './components/ScrollToTop';
import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Ajoutez le composant ScrollToTop ici  que j'avais créé*/}
      <div className="app-container">
        <Navbar />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/histoire" element={<Histoire />} />
            {/* <Route path="/infos-devis" element={<InfosDevis />} /> */}
            <Route path="/contact-horaires" element={<ContactHoraires />} />
            <Route path="/mentions-legales" element={<MentionsLegales />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
