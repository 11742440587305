import React from 'react';
import './MentionsLegales.css';


const MentionsLegales = () => {
  return (
    <div className="legal-container">
      <h2>Mentions Légales</h2>

      <h3>1. Présentation du site</h3>
      <p>
        Le site <a href="https://www.tmz-hair.fr">https://www.tmz-hair.com</a> est édité par :<br />
        <strong>SARL TMZ Hair</strong>, au capital de 1000€, immatriculée sous le numéro SIRET : 83453009900019 et ayant son siège social au 19 rue de la 1ère Armée, 67000 Strasbourg.<br />
        Numéro de TVA : FR36834530099.<br />
        Email : <a href="mailto:abdelghani.zidane@gmail.com">abdelghani.zidane@gmail.com</a>
      </p>

      <p><strong>Responsable de la publication :</strong> Abdelghani ZIDANE</p>
      <p><strong>Hébergeur :</strong> Hostinger International Ltd, 61 Lordou Vironos Street, 6023 Larnaca, Chypre</p>

      <h3>2. Conditions générales d’utilisation</h3>
      <p>
        En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, l’utilisation du site <a href="https://www.tmz-hair.com">https://www.tmz-hair.com</a> implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions peuvent être modifiées ou complétées à tout moment, les utilisateurs du site sont donc invités à les consulter régulièrement.
      </p>

      <h3>3. Propriété intellectuelle</h3>
      <p>
        Tous les éléments présents sur le site <a href="https://www.tmz-hair.com">https://www.tmz-hair.com</a>, y compris les textes, images, graphismes, logos, vidéos et icônes, sont protégés par les dispositions du Code de la Propriété Intellectuelle. Toute reproduction, représentation ou exploitation de ces contenus sans autorisation est strictement interdite (articles L.335-2 et suivants du Code de la Propriété Intellectuelle).
      </p>

      <h3>4. Limitations de responsabilité</h3>
      <p>
        TMZ Hair ne pourra être tenu responsable des dommages directs ou indirects causés au matériel de l’utilisateur lors de l’accès au site. L’utilisateur s’engage à accéder au site en utilisant un matériel récent, exempt de virus, avec un navigateur de dernière génération mis à jour.
      </p>

      <h3>5. Gestion des cookies</h3>
      <p>
        Conformément à la directive 2002/58/CE modifiée par la directive 2009/136/CE et à l’article 82 de la loi Informatique et Libertés du 6 janvier 1978, le site <a href="https://www.tmz-hair.com">https://www.tmz-hair.com</a> utilise des cookies pour améliorer l’expérience de navigation des utilisateurs. Vous pouvez configurer votre navigateur pour accepter ou refuser les cookies.
      </p>

      <h3>6. Liens externes</h3>
      <p>
        Le site <a href="https://www.tmz-hair.com">https://www.tmz-hair.com</a> peut contenir des liens hypertextes vers d’autres sites. TMZ Hair ne peut être tenu responsable du contenu des sites ainsi visités.
      </p>

      <h3>7. Droit applicable</h3>
      <p>
        Tout litige en relation avec l’utilisation du site est soumis au droit français. En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de juridiction aux tribunaux compétents de Strasbourg.
      </p>

      <h3>8. Protection des données personnelles</h3>
      <p>
        Conformément à la loi « Informatique et Libertés » du 6 janvier 1978 modifiée et au Règlement Général sur la Protection des Données (RGPD n° 2016/679), le site <a href="https://www.tmz-hair.com">https://www.tmz-hair.com</a> traite uniquement les données personnelles collectées via les cookies. Vous disposez de droits d'accès, de rectification, de suppression et d'opposition que vous pouvez exercer en nous contactant à l’adresse suivante :<br />
        TMZ Hair – DPO, Abdelghani ZIDANE<br />
        19 rue de la 1ère Armée, 67000 Strasbourg<br />
        Email : <a href="mailto:abdelghani.zidane@gmail.com">abdelghani.zidane@gmail.com</a>
      </p>

      <h3>9. Sécurité et Hébergement</h3>
      <p>
        Le site est hébergé par Hostinger International Ltd sur un serveur situé à Chypre. Hostinger prend toutes les précautions nécessaires pour sécuriser les données et assurer la continuité de service 24/7. TMZ Hair ne pourra être tenu responsable des interruptions de service liées à des problèmes techniques indépendants de sa volonté.
      </p>

      <h3>10. Contact</h3>
      <p>
        Pour toute question relative aux mentions légales ou à l’utilisation du site, vous pouvez nous contacter à l’adresse suivante :<br />
        TMZ Hair – DPO, Abdelghani ZIDANE<br />
        19 rue de la 1ère Armée, 67000 Strasbourg<br />
        Email : <a href="mailto:abdelghani.zidane@gmail.com">abdelghani.zidane@gmail.com</a>
      </p>

      <p>&copy; 2024 TMZ Hair - Tous droits réservés.</p>
    </div>
  );
};

export default MentionsLegales;
