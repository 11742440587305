import React from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './Histoire.css';

const Histoire = () => {

  const [refParcours, inViewParcours] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [refNaissance, inViewNaissance] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [refRencontre, inViewRencontre] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [refCitation, inViewCitation] = useInView({ triggerOnce: true, threshold: 0.2 });

  // Variants pour les animations complexes
  const sectionVariants = {
    hidden: { opacity: 0, y: 100, scale: 0.95 },
    visible: { 
      opacity: 1, 
      y: 0, 
      scale: 1, 
      transition: { 
        duration: 1, 
        ease: [0.6, 0.01, 0.2, 0.95] //  courbe Bézier valide
      } 
    },
  };
  

  const imageVariants = {
    hidden: { opacity: 0, scale: 1.05 },
    visible: { 
      opacity: 1, 
      scale: 1, 
      transition: { 
        duration: 1.2, 
        ease: [0.42, 0, 0.58, 1], 
        delay: 0.3 
      }
    },
  };

  return (
    <div className="histoire-container">
      <Helmet>
        <title>TMZ Hair - L'histoire d'Abdel Zidane</title>
        <meta name="description" content="Découvrez l'histoire inspirante d'Abdel Zidane et de son salon TMZ Hair. Depuis ses débuts au Maroc jusqu'à la création de son salon de coiffure TMZ Hair à Strasbourg." />
      </Helmet>

      {/* Section Le parcours d'Abdel */}
      <motion.section
        className="histoire-section"
        ref={refParcours}
        initial="hidden"
        animate={inViewParcours ? 'visible' : 'hidden'}
        variants={sectionVariants}
      >
        <motion.h2 
          initial={{ opacity: 0, x: -50 }} 
          animate={inViewParcours ? { opacity: 1, x: 0, transition: { duration: 0.7 } } : {}} 
        >
          Le parcours d'Abdel
        </motion.h2>
        <div className="histoire-content">
          <motion.img 
            src={`${process.env.PUBLIC_URL}/images/abdel-parcours.webp`} 
            alt="Le parcours d'Abdel" 
            className="histoire-image" 
            variants={imageVariants}
          />
          <motion.div className="histoire-text">
          <p>
              Abdelghani Zidane, surnommé Abdel, a débuté sa <strong>première expérience de coiffeur au Maroc en 1998</strong>, se formant sur le terrain dans le salon de coiffure d'un proche. En <strong>2000</strong>, il décide de s'installer en <strong>France</strong> et commence à travailler dans un salon à <strong>Béziers</strong>, où il exerce pendant 7 ans. En <strong>2008</strong>, Abdel réalise son rêve en ouvrant son premier salon dans le sud de <strong>Carcassonne</strong>, offrant des services de coiffure et de barberie.
            </p>
            <p>
              En <strong>2010</strong>, il déménage à <strong>Strasbourg</strong> et travaille comme salarié dans un salon pendant <strong>2 ans</strong>, continuant à perfectionner ses compétences. C'est en <strong>2012</strong>, qu'il ouvre son propre salon à la place des Halles (Strasbourg), qu'il gère avec succès jusqu'à la fin de 2017. <br/><br/>Parallèlement, entre <strong>2013 et 2016, Abdel se forme chez Serge Comtesse</strong> et obtient un diplôme qui renforce son expertise.
            </p>
          </motion.div>
        </div>
      </motion.section>

      {/* Section La naissance de TMZ */}
      <motion.section
        className="histoire-section"
        ref={refNaissance}
        initial="hidden"
        animate={inViewNaissance ? 'visible' : 'hidden'}
        variants={sectionVariants}
      >
        <motion.h2 
          initial={{ opacity: 0, x: 50 }} 
          animate={inViewNaissance ? { opacity: 1, x: 0, transition: { duration: 0.7 } } : {}}
        >
          La naissance de TMZ
        </motion.h2>
        <div className="histoire-content">
          <motion.div className="histoire-text">
          <p>
              Après une pause pour des raisons de santé, Abdel reprend son activité <strong>début 2018 en créant TMZ Hair</strong>. Dans ce nouveau salon, son expertise et son amour pour la coiffure brillent plus que jamais. TMZ Hair est rapidement devenu un lieu où chaque client est <strong>accueilli avec chaleur et professionnalisme.</strong> 
            </p>
            <p>Abdel met un point d'honneur à offrir des services de haute qualité, en s'assurant que chaque client reparte non seulement satisfait, mais également ébloui par leur nouvelle coupe ou barbe. Grâce au travail d'Avdel, <strong>TMZ Hair</strong> a su se forger<strong> une excellente réputation à Strasbourg</strong>.</p>
          </motion.div>
          <motion.img 
            src={`${process.env.PUBLIC_URL}/images/NaissanceTmz.webp`} 
            alt="La naissance de TMZ" 
            className="histoire-image-naissance"
            variants={imageVariants}
          />
        </div>
      </motion.section>

      {/* Section Rencontrez Abdel Zidane */}
      <motion.section
        className="histoire-section"
        ref={refRencontre}
        initial="hidden"
        animate={inViewRencontre ? 'visible' : 'hidden'}
        variants={sectionVariants}
      >
        <motion.h2>
          Rencontrez Abdel Zidane
        </motion.h2>
        <div className="histoire-content">
          <motion.img 
            src={`${process.env.PUBLIC_URL}/images/rencontrer-abdel.webp`} 
            alt="Rencontrez Abdel Zidane" 
            className="histoire-image"
            variants={imageVariants}
          />
          <motion.div className="histoire-text">
          <p>
              Abdel Zidane est bien plus qu'un coiffeur. Son point commun avec notre Zizou national ? C'est un <strong>artisan passionné, humble et dévoué.</strong> <br></br>Très ouvert et sociable, il aime le contact humain et sait s'adapter à tous les âges et personnalités. <strong>Il met un point d'honneur à écouter ses clients et à garantir leur satisfaction.</strong> Sa joie de vivre et sa capacité à engager la conversation rendent chaque visite chez TMZ Hair unique et agréable.
            </p>
            <p>
              En dehors de son travail, Abdel aime voyager, écouter de la musique, randonner et suivre les matchs de son équipe favorite, le Real Madrid. Venez découvrir le salon de coiffure d'Abdel, son univers et laissez-vous emporter par son talent et sa bonne humeur.
            </p>
          </motion.div>
        </div>
      </motion.section>

      {/* Section Le mot de la fin */}
      <motion.section
        className="histoire-section"
        ref={refCitation}
        initial="hidden"
        animate={inViewCitation ? 'visible' : 'hidden'}
        variants={sectionVariants}
      >
        <motion.h2>Le mot de la fin !</motion.h2>
        <div className="citation-content">
          <motion.div className="citation-text">
            <p>
             <span>" Zinedine Zidane a fait vibrer les stades, moi je fais briller vos cheveux. (rire) "</span> <br/><br/>- Abdel Zidane
            </p>
          </motion.div>
          <motion.img 
            src={`${process.env.PUBLIC_URL}/images/abdel-portrait-debout.webp`} 
            alt="Le mot de la fin" 
            className="citation-image"
            variants={imageVariants}
          />
        </div>
      </motion.section>
    </div>
  );
};

export default Histoire;
