import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import '../App.css';

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-left">
          <Link to="/mentions-legales">Mentions légales</Link>
          <p>
          &copy; 2024 TMZ Hair - Site fait par{' '}
          <a href="https://linktr.ee/MikaHR" target="_blank" rel="noopener noreferrer" className="mivision-link">
            Mivision
          </a>
        </p>
        </div>
        <div className="footer-center">
          <Link to="/">Accueil</Link>
          <Link to="/histoire">Histoire</Link>
          <Link to="/contact-horaires">Contact & Horaires</Link>
        </div>
        <div className="footer-right">
          <div className="footer-social">
            <a href="https://www.facebook.com/tmzhair" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookF} className="social-icon" />
            </a>
            <a href="https://www.instagram.com/tmz_hair/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="social-icon" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
