import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './Home.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Home = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const controls = useAnimation();
  const expControls = useAnimation();
  const salonControls = useAnimation();

  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [expRef, expInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [salonRef, salonInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  const carouselRef = useRef(null);
  const [carouselInView, setCarouselInView] = useState(false);
  const [centerSlidePercentage, setCenterSlidePercentage] = useState(33.5);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    if (inView) controls.start('visible');
    if (expInView) expControls.start('visible');
    if (salonInView) salonControls.start('visible');
  }, [controls, expControls, salonControls, inView, expInView, salonInView]);

  useEffect(() => {
    const handleResize = () => {
      setCenterSlidePercentage(window.innerWidth < 768 ? 100 : 33.5);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const currentRef = carouselRef.current;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) setCarouselInView(true);
    }, { threshold: 0.1 });

    if (currentRef) observer.observe(currentRef);
    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [carouselRef]);

  const sectionVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: [0.6, 0.01, 0.2, 0.95] } },
  };

  // Tableaux des images du carousel
  const selectedPhotos = [
    `${process.env.PUBLIC_URL}/images/carousel/Coupe1.webp`,
    `${process.env.PUBLIC_URL}/images/carousel/Coupe2.webp`,
    `${process.env.PUBLIC_URL}/images/carousel/Coupe3.webp`,
    `${process.env.PUBLIC_URL}/images/carousel/Coupe4.webp`,
    `${process.env.PUBLIC_URL}/images/carousel/Coupe5.webp`,
    `${process.env.PUBLIC_URL}/images/carousel/Coupe6.webp`,
    `${process.env.PUBLIC_URL}/images/carousel/Coupe7.webp`,
    `${process.env.PUBLIC_URL}/images/carousel/Coupe8.webp`,
    `${process.env.PUBLIC_URL}/images/carousel/Coupe9.webp`,
    `${process.env.PUBLIC_URL}/images/carousel/Coupe10.webp`,
    `${process.env.PUBLIC_URL}/images/carousel/Coupe11.webp`,
  ];

  return (
    <div className="home-container">
      <Helmet>
        <title>Accueil - TMZ Hair</title>
        <meta name="description" content="Bienvenue sur le site de TMZ coiffure, votre salon de coiffure pour homme et enfant" />
      </Helmet>
      
      {/* Header Section */}
      <header className="home-header">
        <img 
          className="home-header-background" 
          src={`${process.env.PUBLIC_URL}/images/EspaceCoiffure.webp`} 
          alt="Espace Coiffure" 
          onLoad={handleImageLoad}
          loading="eager"
        />
        {imageLoaded && (
          <div className="home-header-content">
            <h1>TMZ Hair</h1>
            <h2>Artisan coiffeur & barbier Homme / Enfant</h2>
            <Link to="/histoire" className="call-to-story">L'HISTOIRE DE TMZ</Link>
          </div>
        )}
      </header>

      {/* Intro Section */}
      <motion.section className="home-intro" ref={ref} initial="hidden" animate={controls} variants={sectionVariants}>
        <motion.p className="citation-abdel">
          <span>" Bienvenue chez TMZ Hair, où chaque coupe et soin de la barbe sont réalisés avec passion et expertise. Avec 26 ans de savoir-faire, je m'engage à vous offrir le meilleur de la coiffure et de la barberie. "</span><br/> - Abdel Zidane
        </motion.p>
        <motion.img
          src={`${process.env.PUBLIC_URL}/images/abdel-home.webp`}
          alt="Portrait d'Abdel Zidane"
          className="portrait-abdel"
        />
      </motion.section>

      {/* Expertise Section */}
      <motion.section className="experience-expertise" ref={expRef} initial="hidden" animate={expControls} variants={sectionVariants}>
        <h3>UNE MAIN EXPERTE</h3>
        <div className="experience-content">
          <motion.img 
            src={`${process.env.PUBLIC_URL}/images/main-expert.webp`} 
            alt="main d'expert" 
            className="expert-image"
          />
         <motion.p
            ref={expRef}
            initial="hidden"
            animate={expControls}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: -100 }
            }}
            transition={{ duration: 0.5 }}
          >
            Formé chez Serge Comtesse, Abdel excelle dans l'art de la coiffure et de la barberie. Il sait combiner tradition et innovation pour créer des styles uniques et sur-mesure. <br/><br/> <span className="priority-text">Sa priorité </span>: écouter ses clients et combler leurs attentes. <br/><br/>Venez découvrir un artisan passionné dont l'expertise et le savoir-faire vous garantiront une expérience de coiffure exceptionnelle.
          </motion.p>
        </div>
      </motion.section>

      {/* Salon Section */}
      <motion.section className="salon-pour-tous" ref={salonRef} initial="hidden" animate={salonControls} variants={sectionVariants}>
        <h3>UN SALON POUR TOUS</h3>
        <div className="salon-content">
          <motion.img 
            src={`${process.env.PUBLIC_URL}/images/abdel-client2.webp`} 
            alt="Abdel avec un client" 
            className="salon-image"
          />
          <motion.div>
            <p>Chez TMZ Hair, chaque client est unique. Abdel est reconnu pour son ouverture d'esprit, sa convivialité et sa capacité à s'adapter à tous les âges et à toutes les personnalités. Venez discuter de tout et de rien dans une ambiance joyeuse et chaleureuse, puis ressortez avec une coupe impeccable et une barbe soignée, prêtes à faire tourner les têtes.</p>
          </motion.div>
        </div>
      </motion.section>

      {/* Galerie de Photos Section */}
      <section className="galerie-photo" ref={carouselRef}>
        <h3>LES REALISATIONS</h3>
        {carouselInView && (
          <Carousel
  showArrows={true}
  infiniteLoop={true}
  showThumbs={false}
  showStatus={false}
  autoPlay={true}
  interval={2800}
  centerMode={true}
  centerSlidePercentage={centerSlidePercentage}
  dynamicHeight={true} // pour une hateur responsive
>
  {selectedPhotos.map((photo, index) => (
    <div key={index} className="carousel-photo">
      <img src={photo} alt={`Réalisation ${index + 1}`} />
    </div>
  ))}
</Carousel>
        )}
        <a href="https://www.instagram.com/tmz_hair/" className="instagram-link" target="_blank" rel="noopener noreferrer">En voir plus sur Instagram</a>
      </section>

      {/* Avis Clients Section */}
      <section className="avis-clients">
        <h3>CE QUE DISENT LES CLIENTS</h3>
        <div className="elfsight-container">
          <div className="elfsight-app-0df92b6a-857a-4033-8241-63a88ea65d72" data-elfsight-app-lazy></div>
        </div>
      </section>
    </div>
  );
};

export default Home;
