import React from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import './ContactHoraires.css';

const ContactHoraires = () => {
  return (
    <motion.div 
      className="contacthoraires-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Helmet>
        <title>Contact & Horaires - TMZ Coiffure</title>
        <meta name="description" content="Prenez rendez-vous et consultez nos horaires d'ouverture. Chez TMZ Coiffure, Abdel est à votre disposition pour sublimer vos cheveux." />
      </Helmet>
      <header className="contacthoraires-header">
        <motion.h1 
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          Contact
        </motion.h1>
        <motion.p 
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          Réservez votre créneau par téléphone ou sur place. Abdel vous accueillera avec le sourire !
        </motion.p>
      </header>
      <motion.section 
        className="contacthoraires-content"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <div className="contact-wrapper">
          <motion.div 
            className="cta-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.8 }}
          >
            <a href="tel:+33388368309" className="cta-button">Appelez pour réserver</a>
          </motion.div>
          <div className="contact-details">
            <p><strong>Adresse :</strong> 19 rue de la première Armée, Strasbourg, France</p>
            <p><strong>Téléphone :</strong> <a href="tel:+33388368309">03 88 36 83 09</a></p>
            <p><strong>Email / Contact professionel uniquement:</strong> <a href="mailto:abdelghani.zidane@gmail.com">abdelghani.zidane@gmail.com</a></p>
            <p><strong>Horaires d'ouverture :</strong></p>
            <ul>
              <li>Mardi - Samedi : 9h - 13h, 14h - 19h</li>
              <li>Lundi et Dimanche : Fermé</li>
            </ul>
          </div>
        </div>
        <div className="map-container">
          <motion.h2
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            Où se trouve TMZ ?
          </motion.h2>
          <p className="sub-heading">Le salon vous attend au cœur de Strasbourg 🥨</p>
          <iframe
            title="Salon TMZ Hair - Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2633.4056164873433!2d7.752111315923023!3d48.57964957926469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796c84a85e0d6f1%3A0xbde0b4bc1537b8db!2s19%20Rue%20de%20la%201%C3%A8re%20Arm%C3%A9e%2C%2067000%20Strasbourg%2C%20France!5e0!3m2!1sen!2sfr!4v1637513244014!5m2!1sen!2sfr"
            width="100%"
            height="350"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="google-info-container">
  <h2>Informations complémentaires</h2>
  <p>
    Pour être informé des <strong>fermetures exceptionnelles</strong> ou des horaires spéciaux du salon de coiffure, consultez sa 
    <a href="https://g.co/kgs/12Gfq7A" target="_blank" rel="noopener noreferrer"> page Google</a>. 
    Abdel y partage fréquemment les dernières infos. Vous pouvez aussi y poser vos questions !
</p>

</div>

      </motion.section>
    </motion.div>
  );
};

export default ContactHoraires;
